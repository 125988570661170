.Masonry__Main {
  display: flex;
  justify-content: center;
  padding: 20px 5% 0 5%;
  width: 100%;
  background: #F6F7FB;
  box-shadow: inset 0px 4px 8px 4px rgba(0, 0, 0, 0.05);
}

.Masonry__Column {
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}